import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import Highlight from '../../../components/worksafe-vic/Highlight';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "healthpoint-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#healthpoint-attributes",
        "aria-label": "healthpoint attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HealthPoint attributes`}</h2>
    <p>{`Partners can submit HealthPoint claims via the Tyro Health SDK and obtain transaction status changes via both callbacks and webhooks.`}</p>
    <p><strong parentName="p">{`Creating a transaction`}</strong></p>
    <p><strong parentName="p">{`Use function`}</strong>{`:`}<Highlight mdxType="Highlight">{` medipassTransactionSDK.renderCreateTransaction() `}</Highlight></p>
    <p>{`To process a HealthPoint claim, the following fields apply. Any required field not passed to the SDK will be prompted within the Tyro Health submission page.`}</p>
    <h3 {...{
      "id": "healthpoint-claim-submission-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#healthpoint-claim-submission-attributes",
        "aria-label": "healthpoint claim submission attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HealthPoint claim submission attributes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Type / Format`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Condition`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Example`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`platform`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` Valid values only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The SDK supports two modes - one for payment transactions and another for claims.`}<br />{`Set to: `}<strong parentName="td">{`funder`}</strong>{` for HealthPoint claims`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`funder `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`funder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` Valid values only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For private health and overseas insurance claims set to `}<strong parentName="td">{`phi`}</strong>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`phi`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isRebateEstimate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Boolean`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to true to send as a quote/rebate estimate. `}<strong parentName="td">{`Note that quotes / rebate estimates will not transfer funds - these are a point in time estimates of the fund benefit amount.`}</strong>{` `}<br />{`If not set, defaults to false.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`true`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`providerNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{`Up to 16 alphanumeric characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Servicing provider number or other registration identifier which must be valid and active in Medipass and HealthPoint for a given funder. `}<br />{`For PHI quotes and claims, a Medicare issued provider number should be used where available. Where a Medicare provider number is not applicable, use the appropriate identifier - a Medibank, Fund specific or HealthPoint issued provider number. `}<br />{`The provider number can also be used to set the default terminal identifier (TID) and merchant identifier (MID) if multiple TIDs and MIDs exist for a business.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`0034503W`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invoiceReference`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provider nominated unique transaction reference number, such as an invoice number from the practice management or accounting system. Used for reconciliation purposes.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td"><strong parentName="em"><inlineCode parentName="strong">{`Patient `}</inlineCode></strong></em></td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td"><strong parentName="em"><inlineCode parentName="strong">{` object`}</inlineCode></strong></em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.firstName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` Up to 40 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient first name as on their health fund card`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` John`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.lastName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{`Up to 40 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient last name as on their health fund card`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`Curtin`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.dob`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` YYYY-MM-DD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient Date of Birth in `}{`'`}{`YYYY-MM-DD`}{`'`}{` format.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`1967-11-02`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.reference`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` 2 digits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient unique reference number, sometimes known as the card rank, as shown on health fund card. Only applicable for claims.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`01`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.mobile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional mobile phone number of the member. This can be used for patient searches in the Medipass portalInternational or national notation supported.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`+61411111111 or 0411111111 `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.refId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` Up to 128 alphanumeric characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional but recommended patient unique reference as set by you, such as a UUID. This value is used to uniquely identify a patient in Medipass. Omitting refId will cause duplicate patient records to be created and could make patient searches more difficult for providers who also use the Medipass portal to check on status of a claim and remittance. If supplied and valid, this value will pre-populate patient details from the patient record in the business as set in any prior claim. Any patient details sent for an existing patient record will update those values.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 5AD5E2AF-69D2-43D3-9321-428495205E5E `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td"><strong parentName="em"><inlineCode parentName="strong">{`claimableItems`}</inlineCode>{` `}</strong></em></td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td"><strong parentName="em">{`Array`}</strong>{`{Object}`}<br />{` Array of fund claimable items.`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].serviceDateString`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{`YYYY-MM-DD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the service in `}{`'`}{`YYYY-MM-DD`}{`'`}{` format.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`2022-01-12`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].itemCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{`5 numeric`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Item code - note item code must be valid for HealthPoint, the provider registered profession and service date. For podiatry and dentistry item codes, remember to remove any leading alpha characters such as P`}{`*`}{` or D`}{`*`}{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`5001`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].price`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` Currency notation, up to 6 numeric and 2 decimal digits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gross (including GST) price each unit in $XX.XX format. This will be the amount claimed with the health fund.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` $15.95`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`phi.claimItems.clinicalCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` 2 alphanumeric`}{`*`}{` Note 3 alphanumeric is permitted for HCF quotes/claims`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Two digit code. These may be required by some private health funds. Only applicable for: `}<br />{`• Dentistry: as two digit Tooth ID`}<br />{`• Psychology: as two digit Primary Condition code`}<br />{`• Occupational therapy: - digit one as Primary Condition indicator-  digit two as Diagnostic Intervention indicator.`}<br />{`For HCF only: the ICD short code used in the `}{`"`}{`More for`}{`"`}{` program.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`12`}</Highlight></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "claim-response-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#claim-response-attributes",
        "aria-label": "claim response attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Claim response attributes`}</h3>
    <p>{`When a transaction is processed, the SDK will pass through the transaction details to the first parameter of the onSuccess callback.`}</p>
    <p>{`The response payload contains a number of attributes with a complete description of the transaction model in our online API documentation.`}</p>
    <p><strong parentName="p">{`Key response attributes include`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Type / Format`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Example`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`_`}{`id`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` 64-128 bit ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`a unique transaction level identifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`12BA46C3-BA6B-4192-9368-BD78AFAAB489`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Overall transaction status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` Approved`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`amountClaimsBenefit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` Currency notation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total benefit paid by health fund`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`$60.00`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`amountBalance`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` Currency notation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any unpaid amount on aggregate charges. If gap is successfully processed via EFTPOS in same transaction, this will be zero`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` $0.00`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`claims[].status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Overall claim status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`Approved`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`claims[].gatewayRefId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique transaction reference from health fund`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 1245572346`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{` `}<strong parentName="em">{`For each claimableItem`}</strong>{` `}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`claims[].claimItems[].status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Individual items can be approved or declined`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`Approved`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`claims[].claimItems[].amountBenefit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` Currency notation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Benefit paid on item by health fund`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` $60.00`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`claims[].claimItems[].amountGap`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` Currency notation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gap amount - difference between charged and benefit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` $40.00`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`claims[].claimItems[].clientMessage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message to display to user (usually for declined items)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`00 - Approved`}</Highlight></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      